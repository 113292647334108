import React from "react";
//import CookieManager from "./src/components/general/cookie-panel/CookieManager";
import {createTheme, ThemeProvider} from "@material-ui/core";
import {LangProvider} from "./src/components/general/lang-manager/LangManager";
import TrackingScripts from "./src/components/general/cookie-panel/TrackingScripts";

const theme = createTheme({
    palette: {
        primary: {
            light: '#64D2F3',
            main: '#00AEEF',
            dark: '#113E6C',
            contrastText: '#fff',
        },
    },
});

export const wrapRootElement = ({element}) => {

    return (
        <ThemeProvider theme={theme}>
            <LangProvider>
                {element}
                <TrackingScripts allowed/>
            </LangProvider>
        </ThemeProvider>
    );
}
