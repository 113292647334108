import React from 'react';
import { Helmet } from 'react-helmet';

const TrackingScripts = ({ allowed }) => {
    return allowed ? (
        <Helmet>
            <script id="Cookiebot" src="//consent.cookiebot.com/uc.js" data-cbid="d83cd00c-5e23-4df3-a9f5-f36e8b440faf" data-blockingmode="auto" type="text/javascript" />
            <script type="text/javascript" src="/tracking/gtag-helper.js" async />
            <script type="text/javascript" src="/tracking/gtag.js" async />
            <script type="text/javascript" src="/tracking/hotjar.js" async />
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/27161874.js" />
        </Helmet>
    ) : null;
};

export default TrackingScripts;
