import React, {createContext, useContext, useEffect, useState} from 'react';

export const LangContext = createContext("");

export function LangProvider({children}) {
    const [langTokens, setLangTokens] = useState("")

    useEffect(()=>{
        if (typeof navigator === `undefined`) {
            setLangTokens("en")
        }
        const isHungarian = navigator && navigator.language && navigator.language.toLowerCase().includes("hu")
        setLangTokens(isHungarian ? "hu" : "en")
    },[])

    return <LangContext.Provider value={{langTokens}}>{children}</LangContext.Provider>
}

export function useLang() {
    const context = useContext(LangContext)
    if (context === undefined) {
        console.log("HIBA - üres a context")
    }

    return context
}
